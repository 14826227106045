<template>
  <div>

    <b-card bg-variant="Default">
      <div class="d-flex flex-row bd-highlight pb-2">
        <b-button
        variant="primary"
        :to="{ name: 'crm-leads-in-attesa' }" 
        class="mr-1 flex-md-grow-1"
        >In Attesa
        </b-button>
        <b-button
        variant="outline-primary"
        :to="{ name: 'crm-leads-in-corso' }"
        class="mr-1 flex-md-grow-1"
        >In Corso
        </b-button>
        <b-button
        variant="outline-primary"
        :to="{ name: 'crm-leads-conlcuse' }"
        class="mr-1 flex-md-grow-1"
        >Concluse
        </b-button>
        <b-button
        variant="outline-primary"
        :to="{ name: 'crm-leads-nel-cestino' }"
        class="mr-1 flex-md-grow-1"
        >Nel Cestino
        </b-button>

      </div>
      
      <!-- table -->
      <vue-good-table
      mode="remote"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      :totalRows="totalRecords"
      :rows="rows"
      :columns="columns"
      :isLoading.sync="isLoading"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
      :pagination-options="{
          enabled: true,
          mode: 'pages',
          nextLabel: 'successiva',
          prevLabel: 'precedente',
          rowsPerPageLabel: 'righe per pagina',
          ofLabel: 'di',
          pageLabel: 'pagina', // for 'pages' mode
          perPage: 10
      }"
      styleClass="vgt-table">

        <template slot="loadingContent_stop">
          <div class="bg-primary"> animated loading ....</div>
        </template>

        <div slot="emptystate"> <div class="text-center"> Nessun dato disponibile </div> </div>

        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Ordine -->
          <div
            v-if="props.column.field === 'numero_ordine'"
          >
            <span class="font-weight-bolder">
              {{ props.row.numero_ordine }}
            </span><br />
            <span class="font-small-2 text-muted">
              di {{ props.row.data_inserimento }}
            </span>
          </div>



          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <div class="text-nowrap">
              <feather-icon
                  :id="`tabellaordini-riga-${props.row.id}-riepilogo`"
                  icon="FileTextIcon"
                  class="cursor-pointer mr-1"
                  size="21"
                  @click="$router.push({ name: 'crm-lead-dettaglio', params: { id_lead: props.row.id, id_azienda: props.row.id_azienda }})"
              />
              <b-tooltip
                  title="riepilogo ordine"
                  class="cursor-pointer"
                  :target="`tabellaordini-riga-${props.row.id}-riepilogo`"
              />
              <feather-icon
                  :id="`tabellatest1-riga-${props.row.id}-elimina`"
                  icon="XCircleIcon"
                  class="cursor-pointer mr-1"
                  size="21"
                  @click="cancellaRiga(props.row.id,props.row.id_azienda,userData.id)"
              />
              <b-tooltip
                  title="sposta nel cestino"
                  class="cursor-pointer"
                  :target="`tabellatest1-riga-${props.row.id}-elimina`"
              />
              
            </div>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        
      </vue-good-table>

    </b-card>
  </div>
</template>

<script>
import router from '@/router'
import { BRow, BCol } from 'bootstrap-vue'
import { BCard, BAvatar, BBadge, BFormGroup, BFormInput, BFormSelect, BButton, BDropdown, BDropdownItem, BTooltip } from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'

export default {
  components: {
    BRow,
    BCol,

    BCard,
    VueGoodTable,
    BButton,
    BTooltip,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      idLinkSelectd: 1,
      isLoading: true,
      emptystate: true,
      pageLength: 10,
      columns: [
        {
          label: 'Azienda',
          field: 'nome_azienda',
          filterOptions: {
            enabled: true,
            placeholder: 'Ricerca',
          },
        },
        {
          label: 'Richiesta',
          field: 'tipo_nome',
          filterOptions: {
            enabled: true,
            placeholder: 'Ricerca',
          },
        },
        {
          label: 'Score',
          field: 'score',
          filterOptions: {
            enabled: true,
            placeholder: 'Ricerca',
          },
        },
        {
          label: 'Stato',
          field: 'stato_nome',
          filterOptions: {
            enabled: true,
            placeholder: 'Ricerca',
          },
        },
        {
          label: 'Creazione',
          field: 'data_inserimento',
          filterOptions: {
            enabled: true,
            placeholder: 'Ricerca',
          },
        },
        {
          label: 'Aggiornamento',
          field: 'data_aggiornamento',
          filterOptions: {
            enabled: true,
            placeholder: 'Ricerca',
          },
        },
        {
          label: 'Operazioni',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      totalRecords: 0,
      serverParams: {
        columnFilters: {
        },
        sort: [
            {
            field: '',
            type: ''
            }
        ],
        page: 1, 
        perPage: 10,
        licenza_pubblica: 'testlicenza'
      },
      userData: {},
    }
  },
  created() {
    this.userData = JSON.parse(localStorage.getItem('userData'))

    //this.rows = [];
    this.loadItems(this.userData.id,this.idLinkSelectd);
    
  },
  methods: {
    updateParams(newProps) {
      //console.log("updateParams --- vvv ")
      console.log(newProps)
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    
    onPageChange(params) {
      this.updateParams({page: params.currentPage});
      this.loadItems(this.userData.id,this.idLinkSelectd);
    },

    onPerPageChange(params) {
      this.updateParams({perPage: params.currentPerPage});
      this.loadItems(this.userData.id,this.idLinkSelectd);
    },

    onSortChange(params) {
      this.updateParams({
        sort: [{
          type: params.sortType,
          field: this.columns[params.columnIndex].field,
        }],
      });
      this.loadItems(this.userData.id,this.idLinkSelectd);
    },
    
    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems(this.userData.id,this.idLinkSelectd);
    },

    // load items is what brings back the rows from server
    loadItems(id_utente_admin) {
        
        console.log("loadItems ......");
        //console.dir(this.serverParams)
        //this.isLoading = true;

        //this.updateParams({token_user: "fanco-maria-123"})
        
        this.$http.get('v2/crmadmin/lista_leads/'+id_utente_admin+'/'+this.idLinkSelectd, {
            params: this.serverParams 
            }).then(response => { 
                
                //console.log("risposta vvv"); 
                //console.log(response.data)
                //console.log('--- ---')
                console.dir(response.data)
                console.log("response.totalRecords -> "+response.data[0].contarighe);
                
                //console.log(response.data.rows)
                
                this.totalRecords = response.data[0].contarighe;
                this.rows = response.data[1].righe;
                
                //rimuovi il loading dopo il caricamento dei dati nella tabella
                this.isLoading = false;            
            })    
    },
    cancellaRiga(id,id_azienda,id_utente_admin){
      console.log("click cancellaRiga ...... id -> "+id+" | id_azienda -> "+id_azienda+" | id_utente_admin -> "+id_utente_admin);

      this.$swal({
        title: 'Sei sicuro di voler procedere?',
        text: "conferma lo spostamento della richiesta nel cestino",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'SI, Procedi',
        cancelButtonText: 'Annulla operazione',
        customClass: {
          confirmButton: 'btn btn-outline-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

            this.$http.get('v2/crmadmin/softdeletelead/'+id+'/'+id_azienda+'/'+id_utente_admin)
            .then(response => { 
                console.log("risposta server --------")
                console.log(response.data)
                console.log("response.data.dati.id_cancellato -> "+response.data.dati.id_cancellato)

                if((response.data.statusCode===200) && (response.data.dati.id_cancellato == id)){
                //risposta positiva
                    this.$swal({
                        icon: 'success',
                        title: 'Spostato nel cestino',
                        text: 'operazione effettuata correttamente',
                        confirmButtonText: 'chiudi',
                        customClass: {
                        confirmButton: 'btn btn-success',
                        },
                    })

                    //aggiorna tabella
                    this.loadItems(this.userData.id,this.idLinkSelectd);
                    
                } else {
                //risposta negativa (errore sul server)
                    this.$swal({
                        icon: 'error',
                        title: 'Si è verificato un errore',
                        text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema - ERRORE DA-004',
                        customClass: {
                        confirmButton: 'btn btn-outline-primary',
                        },
                    })
                }
            }).catch(e => {
                console.log(e);
            })
        }
      })
    },

  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
